import {
  Container,
  Header,
  Description,
  Logo,
  About,
  Underline,
  LogoIcon,
  LogoTitle,
} from "./styled";
import { useTranslation } from "react-i18next";

function AboutUs() {
  const { t } = useTranslation();

  return (
    <Container>
      <Header>
        <About>
          <div>{t("Hakkımızda")}</div>
          <Underline />
        </About>
        <Logo>
          <LogoIcon />
          <LogoTitle>Sürer Metal</LogoTitle>
        </Logo>
      </Header>
      <Description>
        {t(
          "1975 yılında Alsa Alüminyum adı altında imalata başlayıp günümüzde Sürer Metal olarak özellikle mutfak eşyaları başta olmak üzere elektrik, aydınlatma, havalandırma, aksesuar vb. üretim yapan firmalara Alüminyum Levha ve Disk üretmekteyiz.",
        )}
      </Description>
    </Container>
  );
}

export { AboutUs };
