import { Link } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  height: 100%;
  padding: 55px 117px 56px 115px;
  display: flex;
  flex-direction: column;

  @media (max-width: 1115px) {
    padding: 55px 50px 56px 50px;
  }

  @media (max-width: 450px) {
    padding: 44px 4px;
  }
`;
const Banner = styled.img`
  display: block;

  max-width: 1207px;
  max-height: 420px;

  width: 100%;
  height: auto;
  align-self: center;

  @media (max-width: 450px) {
    display: none;
  }
`;
const BannerMobile = styled.img`
  display: none;

  @media (max-width: 450px) {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

const Products = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 151px;
  margin-left: 1px;
  min-width: -webkit-fill-available;
  align-self: center;
  @media (max-width: 990px) {
    margin-bottom: 62px;
  }
  @media (min-width: 1442px) {
    min-width: 1207px;
  }
`;
const Line = styled.div`
  width: 2px;
  height: 138px;
  background-color: black;
  align-self: flex-end;
  margin-bottom: 9px;
  @media (max-width: 990px) {
    display: none;
  }
`;
const ProductGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 98px;

  @media (max-width: 990px) {
    flex-direction: column;
    margin-top: 12px;
  }
`;
const Product = styled(Link)`
  @media (max-width: 990px) {
    padding: 0 50px;
    align-self: center;
  }
  @media (max-width: 364px) {
    padding: 0 30px;
    align-self: center;
  }
`;
const ProductTitle = styled.div`
  margin-bottom: 19px;
  font-size: 18px;
  font-family: Sansation-Bold;
  @media (max-width: 990px) {
    margin-top: 14px;
  }
`;
const ProductImage = styled.img`
  border-radius: 13px;
`;
const ProductsTitle = styled.div`
  display: none;
  @media (max-width: 990px) {
    font-family: Sansation-Bold;
    font-size: 24px;
    display: block;
    margin-top: 21px;
    margin-left: 8px;
  }
`;

const Underline = styled.div`
  display: none;
  @media (max-width: 990px) {
    display: block;
    background-color: #73777c;
    width: 227.5px;
    margin-top: 11px;
    height: 2px;
    margin-left: 7px;
  }
`;

export {
  Line,
  Container,
  Banner,
  BannerMobile,
  Products,
  ProductsTitle,
  Product,
  ProductGroup,
  ProductTitle,
  ProductImage,
  Underline,
};
