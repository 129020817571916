import {
  Container,
  Banner,
  BannerMobile,
  ProductGroup,
  Products,
  Product,
  ProductsTitle,
  ProductTitle,
  Line,
  ProductImage,
  Underline,
} from "./styled";
import { useTranslation } from "react-i18next";

function HomePage() {
  const { t } = useTranslation();

  let banner = t("banner.png");
  let bannerMobile = t("mobilbanner.png");

  return (
    <Container>
      <Banner src={`./${banner}`} />
      <BannerMobile src={`./${bannerMobile}`} />

      <ProductsTitle>{t("Ürünlerimiz")}</ProductsTitle>
      <Underline />
      <Products>
        <ProductGroup>
          <Product to="/urunlerimiz">
            <ProductTitle>{t("Alüminyum Disk")}</ProductTitle>
            <ProductImage
              src="./images/aluminiumdisk.jpg"
              alt="Alüminyum Disk"
            />
          </Product>
          <Line />
          <Product to="/urunlerimiz">
            <ProductTitle>{t("Alüminyum Levha")}</ProductTitle>
            <ProductImage
              src="./images/aluminiumlevha.jpg"
              alt="Alüminyum Levha"
            />
          </Product>
          <Line />
          <Product to="/urunlerimiz">
            <ProductTitle>{t("Alüminyum Külçe")}</ProductTitle>
            <ProductImage
              src="./images/aluminiumkulce.jpg"
              alt="Alüminyum Külçe"
            />
          </Product>
        </ProductGroup>
      </Products>
    </Container>
  );
}

export { HomePage };
