import styled, { css } from "styled-components";
import { Link, NavLink } from "react-router-dom";
import HeaderTexture from "./header-texture.jpg";
import { ReactComponent as Logo } from "./logo.svg";
import { ReactComponent as Hamburger } from "./hamburger-menu.svg";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  @media (max-width: 990px) {
    flex-direction: column;
  }
`;

const Top = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 178px 3px 4px;
  background-image: url(${HeaderTexture});
  background-repeat: round;

  @media (max-width: 1110px) {
    padding: 5px 75px 3px 4px;
  }

  @media (max-width: 990px) {
    justify-content: space-between;
    position: relative;
    padding: 4px 6px 0px 3px;
  }
`;

const StyledLink = styled(NavLink)`
  margin: 0px 39.8px;
  font-size: 18px;
  font-family: Sansation-Bold;

  &.isSelected,
  :hover {
    position: relative;

    &:before {
      position: absolute;
      content: " ";
      background-image: linear-gradient(
        to right,
        black,
        black,
        white,
        black,
        black
      );
      width: 71px;
      height: 6px;
      bottom: -36px;
      cursor: default;
      margin: auto;
      left: 0;
      right: 0;
    }
  }
`;

const LogoContainer = styled(Link)`
  display: flex;
`;

const StyledLogo = styled(Logo)`
  margin-right: 8px;

  @media (max-width: 990px) {
    width: 61.77px;
    height: 61px;
  }
`;

const Title = styled.div`
  font-size: 36px;
  font-family: Sansation-Bold;
  padding-bottom: 6px;
  align-self: center;

  @media (max-width: 400px) {
    font-size: 28px;
  }
`;
const Navigation = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1 1;
  @media (max-width: 990px) {
    display: none;
  }
`;

const Underline = styled.div`
  height: 6px;
  background-color: black;
  @media (max-width: 990px) {
    height: 7px;
  }
`;
const MenuOverlay = styled.div`
  position: absolute;
  left: 0;
  width: 71px;
  height: 6px;
  background-image: linear-gradient(
    to right,
    black,
    black,
    white,
    black,
    black
  );
`;

const HamburgerMenu = styled.div`
  display: none;
  @media (max-width: 990px) {
    display: block;
  }
`;
const MobileLinks = styled.ul``;
const MenuContent = styled.div`
  background-color: #c4c4c4;
  padding: 57px 11px 40px 52px;
  position: absolute;
  right: 0;
  top: 72px;
  border: 2px solid #000000;
  font-family: Sansation-Bold;
  font-size: 18px;
  overflow: hidden;
  z-index: 999;
  clip-path: polygon(19% 0, 100% 0%, 100% 100%, 0 100%);
  &:after {
    content: "";
    top: 0px;
    left: 8px;
    width: 5px;
    height: 100%;
    transform: skew(353deg);
    border-right: 3px solid black;
    position: absolute;
  }
`;
const MobileLink = styled(NavLink)``;
const MenuIcon = styled(Hamburger)`
  display: none;
  @media (max-width: 990px) {
    display: flex;
    cursor: pointer;
  }
`;
const ListItem = styled.li`
  margin-bottom: 18px;

  ${({ isSelected }) => {
    if (isSelected) {
      return css`
        list-style-type: disc;
      `;
    } else {
      return css`
        list-style-type: circle;
      `;
    }
  }}
`;

const ButtonEN = styled.button`
  position: absolute;

  ${({ isSelected }) => {
    if (isSelected) {
      return css`
        background-color: #3f4146;
        color: white;
        border-radius: 0 0 10px 10px;
        padding: 4px 21px 5px 21px;
        bottom: -24px;
        right: 92px;
        z-index: 2;
        @media (max-width: 990px) {
          bottom: -31px;
          right: 64px;
        }
      `;
    } else {
      return css`
        background-color: #afafaf;
        color: black;
        border-radius: 0 0 10px 10px;
        padding: 3px 22px 1px 16px;
        bottom: -19px;
        right: 95px;
        z-index: 1;

        @media (max-width: 990px) {
          bottom: -26px;
          right: 67px;
        }
      `;
    }
  }}
`;

const ButtonTR = styled.button`
  position: absolute;

  ${({ isSelected }) => {
    if (isSelected) {
      return css`
        background-color: #3f4146;
        color: white;
        border-radius: 0 0 10px 10px;
        padding: 4px 21px 5px 21px;
        bottom: -24px;
        right: 41px;
        z-index: 2;
        @media (max-width: 990px) {
          bottom: -31px;
          right: 13px;
        }
      `;
    } else {
      return css`
        background-color: #afafaf;
        color: black;
        border-radius: 0 0 10px 10px;
        padding: 3px 16px 1px 22px;
        bottom: -19px;
        right: 41px;
        z-index: 1;
        @media (max-width: 990px) {
          bottom: -26px;
          right: 13px;
        }
      `;
    }
  }}
`;

export {
  Container,
  LogoContainer,
  StyledLink as Link,
  StyledLogo as Logo,
  Title,
  Navigation,
  Underline,
  Top,
  MenuOverlay,
  HamburgerMenu,
  MobileLinks,
  MobileLink,
  MenuIcon,
  ListItem,
  MenuContent,
  ButtonEN,
  ButtonTR,
};
