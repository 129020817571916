import styled from "styled-components";
import { ReactComponent as CapAraligi } from "./cap_araligi.svg";
import { ReactComponent as KalinlikAraligiLevha } from "./kalinlik_araligi_levha.svg";
import { ReactComponent as KalinlikAraligi } from "./kalinlik_araligi.svg";
import { ReactComponent as MaxOlculer } from "./max_olculer.svg";
import { ReactComponent as MinOlculer } from "./min_olculer.svg";
import { Link } from "react-router-dom";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 23px 2px 0px 43px;
  @media (max-width: 1160px) {
    margin: 50px 0;
  }
`;
const Title = styled.div`
  margin-bottom: 11px;
  font-size: 36px;
  font-family: Sansation-Bold;
  @media (max-width: 1160px) {
    font-size: 24px;
    margin-bottom: 7px;
  }
`;

const Underline = styled.div`
  background-color: black;
  width: 361px;
  height: 5px;
  @media (max-width: 1160px) {
    width: 162px;
    height: 2px;
  }
`;

const Header = styled.div`
  margin-bottom: 51px;
  @media (max-width: 1160px) {
    padding: 0 23px;
    margin-bottom: 17px;
  }
`;
const ProductContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 45px 150px 45px;

  @media (max-width: 1160px) {
    flex-direction: column;
    margin: 0;
    justify-content: unset;
  }
`;
const ProductTitle = styled.div`
  margin-bottom: 29px;
  font-size: 24px;
  font-family: Sansation-Bold;
`;
const ProductTitleKulce = styled.div`
  margin-bottom: 30px;
  font-size: 24px;
  font-family: Sansation-Bold;

  @media (max-width: 1160px) {
    margin-bottom: 29px;
  }
`;
const Product = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 18px;
  position: relative;
  &:not(:first-child):not(:last-child) {
    padding: 0 0 0 91px;
  }

  &:last-child {
    padding-left: 95px;
  }

  @media (max-width: 1240px) {
    &:last-child {
      padding-left: 50px;
    }
  }

  @media (max-width: 1190px) {
    &:last-child {
      padding: 0 26px 27px 55px;
    }
  }

  @media (max-width: 1160px) {
    border-radius: 34px;
    background-color: #d2d2d2;
    font-size: 16px;
    padding: 26px 43px 27px 55px;
    margin-bottom: 50px;
    align-items: center;

    &:last-child {
      padding: 21px 26px 27px 55px;
    }

    &:not(:first-child):not(:last-child) {
      padding: 21px 38px 27px 53px;
    }
  }

  @media (max-width: 375px) {
    border-radius: 34px;
    background-color: #d2d2d2;
    font-size: 16px;
    padding: 26px 43px 27px 55px;
    margin-bottom: 50px;
    align-items: unset;

    &:not(:first-child):not(:last-child) {
      padding: 21px 38px 27px 53px;
    }
  }
`;
const ProductDetail = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 1160px) {
    height: 449px;

    &:not(:first-child):not(:last-child) {
      height: 454px;
    }
  }
`;
const ProductImage = styled.img`
  width: 264px;
  height: 152px;
  border-radius: 10px;
`;
const ListItem = styled.li`
  align-items: center;
  display: flex;
  margin-top: 35px;
  position: relative;
`;

const Kulce = styled.li`
  max-width: 338px;
  font-size: 18px;
  font-family: sansation-bold;
  margin-top: 41px;

  @media (max-width: 1160px) {
    font-size: 16px;
    max-width: 281px;
    margin-top: 44px;
  }
`;
const OrderForm = styled(Link)`
  padding: 17px 0;
  font-size: 18px;
  font-family: Sansation-Bold;
  background-color: #bcbcbc;
  border-radius: 13px;
  box-shadow: 0 4px 8px 0 #000000;
  max-width: 247px;
  margin: 66px 0 82px 0;
  text-align: center;
  align-self: flex-end;
  padding: 17px 47px 17px 45px;
  @media (max-width: 1330px) {
    align-self: center;
  }
  @media (max-width: 1160px) {
    padding: 17px 45px 17px 45px;
    margin-top: 0;
    margin-bottom: 0px;
  }
  @media (max-width: 375px) {
    padding: 17px 45px 17px 45px;
    margin-top: 0;
    margin-bottom: 0px;
    align-self: flex-start;
  }
`;

const MaxOlculerIcon = styled(MaxOlculer)`
  position: absolute;
  left: -70px;
  bottom: 0px;
  @media (max-width: 1160px) {
    left: -53px;
    bottom: 4px;
  }
`;

const MinOlculerIcon = styled(MinOlculer)`
  position: absolute;
  left: -59px;
  bottom: 4px;
  @media (max-width: 1160px) {
    left: -42px;
    bottom: 3px;
  }
`;

const KalinlikAraligiIcon = styled(KalinlikAraligi)`
  position: absolute;
  left: -66px;
  bottom: 4px;
  @media (max-width: 1160px) {
    left: -48px;
    bottom: 3px;
  }
`;

const KalinlikAraligiLevhaIcon = styled(KalinlikAraligiLevha)`
  position: absolute;
  left: -65px;
  bottom: 3px;

  @media (max-width: 1160px) {
    left: -48px;
    bottom: 0px;
  }
`;

const CapAraligiIcon = styled(CapAraligi)`
  position: absolute;
  left: -58px;
  bottom: 1px;
  @media (max-width: 1160px) {
    left: -41px;
    bottom: 3px;
  }
`;

const Line = styled.div`
  width: 2px;
  height: 500px;
  background-color: #73777c;
  position: absolute;
  left: -2px;
  bottom: -7px;

  @media (max-width: 1160px) {
    display: none;
  }
`;

const Top = styled.div`
  &:not(:last-child) {
    padding-right: 57px;
  }

  @media (max-width: 1160px) {
    &:not(:last-child) {
      padding-right: 0;
    }
  }
`;

export {
  Container,
  Product,
  ProductTitle,
  ProductTitleKulce,
  ProductContainer,
  Underline,
  Header,
  Title,
  ListItem,
  OrderForm,
  ProductDetail,
  ProductImage,
  MaxOlculerIcon,
  MinOlculerIcon,
  KalinlikAraligiIcon,
  KalinlikAraligiLevhaIcon,
  CapAraligiIcon,
  Line,
  Top,
  Kulce,
};
