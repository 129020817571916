import styled from "styled-components";
import Rectangle from "./rectangle.svg";
import { ReactComponent as LogoIcon } from "./logo.svg";
const Container = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  border-radius: 87px;
  border: solid 5px #000000;
  margin: 63px 91px 359px 91px;
  padding: 29px 47px 174px 59px;
  position: relative;
  overflow: hidden;
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;

  &:before {
    content: "";
    background-image: url(${Rectangle});
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
  }

  @media (max-width: 1300px) {
    max-width: 1000px;
  }

  @media (max-width: 1150px) {
    max-width: 900px;
  }

  @media (max-width: 1050px) {
    max-width: 850px;
  }

  @media (max-width: 990px) {
    border: solid 2px #000000;
    border-radius: 14px;
    padding: 8px 14px 52px 21px;
    margin: 60px 23px 0 17px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 74px;
  z-index: 2;

  @media (max-width: 990px) {
    margin-bottom: 47px;
  }
`;

const Description = styled.div`
  font-size: 24px;
  text-align: center;
  margin-left: 16px;
  z-index: 2;

  @media (max-width: 990px) {
    font-size: 18px;
    margin-left: 0;
  }
`;

const Logo = styled.div`
  font-size: 36px;
  font-family: Sansation-Bold;
  display: flex;
  align-items: center;
  margin-right: 44px;

  @media (max-width: 990px) {
    font-size: 17px;
    margin-right: 0;
  }
`;

const About = styled.div`
  margin-top: 4px;
  font-size: 36px;

  @media (max-width: 990px) {
    font-size: 18px;
    font-family: Sansation-Bold;
    margin-top: 13px;
  }
`;

const Underline = styled.div`
  background-color: black;
  width: 473px;
  margin-top: 14px;
  height: 5px;

  @media (max-width: 990px) {
    width: 136px;
    height: 2px;
  }
`;

const StyledLogoIcon = styled(LogoIcon)`
  margin-right: 8px;

  @media (max-width: 990px) {
    width: 35px;
    height: 35px;
    margin-right: 3px;
  }
`;

const LogoTitle = styled.div`
  margin-bottom: 5px;
`;

export {
  Container,
  Header,
  Description,
  Logo,
  About,
  Underline,
  StyledLogoIcon as LogoIcon,
  LogoTitle,
};
