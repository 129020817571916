import {
  Header,
  Container,
  Title,
  Underline,
  Body,
  Map,
  AddressIcon,
  SmallAddressIcon,
  Information,
  WebsiteIcon,
  PhoneIcon,
  MailIcon,
  BriefCaseIcon,
  ListItem,
  AddressContainer,
  PhoneContainer,
  MailContainer,
  WebsiteContainer,
  InfoTitle,
  InstagramIcon,
  FacebookIcon,
  SocialMedia,
  List,
  WhatsappContainer,
  OrderForm,
  InfoText,
  WhatsappIcon,
  OrderFormContainer,
  ContactNumbers,
  Contact,
  MapContainer,
  Bottom,
  MapButtonContainer,
  GoogleMapsIcon,
  WhatsappText,
} from "./styled";
import { useTranslation } from "react-i18next";

function ContactUs() {
  const { t } = useTranslation();

  return (
    <Container>
      <Header>
        <Title>{t("İletişim")}</Title>
        <Underline />
      </Header>
      <Body>
        <Information>
          <List>
            <ListItem>
              <SmallAddressIcon />
              <AddressContainer>
                <InfoTitle>{t("Adres")}:</InfoTitle>
                <div>
                  Maltepe mah. Gümüşsuyu Caddesi 9/2 Zeytinburnu İstanbul
                </div>
              </AddressContainer>
            </ListItem>

            <ListItem>
              <PhoneIcon />
              <PhoneContainer>
                <InfoTitle>{t("Telefon")}:</InfoTitle>
                <ContactNumbers>
                  <Contact>
                    <div>Ahmetcan Sürer</div>
                    <div>0532 624 35 04</div>
                  </Contact>
                  <Contact>
                    <div>Metin Sürer</div>
                    <div>0532 235 69 43</div>
                  </Contact>
                </ContactNumbers>
              </PhoneContainer>
            </ListItem>

            <ListItem>
              <MailIcon />
              <MailContainer>
                <InfoTitle>{t("Mail")}:</InfoTitle>
                <div>info@surermetal.com</div>
              </MailContainer>
            </ListItem>

            <ListItem>
              <WebsiteIcon />
              <WebsiteContainer>
                <InfoTitle>{t("Web Sitesi")}:</InfoTitle>
                <div>www.surermetal.com</div>
              </WebsiteContainer>
            </ListItem>
          </List>
          <Bottom>
            <SocialMedia>
              <a href="https://www.facebook.com/surermetal/">
                <FacebookIcon />
              </a>
              <a href="https://www.instagram.com/surermetal/">
                <InstagramIcon />
              </a>
            </SocialMedia>
            <a
              href="https://api.whatsapp.com/send?phone=905326243504"
              target="_blank"
              rel="noreferrer"
            >
              <WhatsappContainer>
                <WhatsappIcon />
                <WhatsappText>
                  {t("Whatsapp üzerinden hemen iletişime geçmek için tıklayın")}
                </WhatsappText>
              </WhatsappContainer>
            </a>
            <MapButtonContainer
              onClick={() => {
                window.open(
                  "https://maps.google.com?q=Maltepe, Gümüşsuyu Caddesi no 9, 34010 Zeytinburnu/İstanbul",
                );
              }}
            >
              <GoogleMapsIcon />
              <div>{t("Harita için tıklayın")}</div>
            </MapButtonContainer>
            <OrderFormContainer>
              <OrderForm to="/siparis-kaydi">
                <BriefCaseIcon />
                <div>{t("Sipariş kaydı oluştur")}</div>
              </OrderForm>
              <InfoText>
                {t(
                  "Sipariş kaydı oluşturun size en kısa sürede dönüş yapalım.",
                )}
              </InfoText>
            </OrderFormContainer>
          </Bottom>
        </Information>
        <MapContainer>
          <AddressIcon />
          <Map>
            <iframe
              title="map"
              className="map-top"
              width="100%"
              height="100%"
              src="https://www.google.com/maps/embed/v1/place?key=AIzaSyCYbs8OVRFNNeMSmKZ4OM6eGQ_b5-SZZmc
          &amp;q=Maltepe, Gümüşsuyu Caddesi no 9, 34010 Zeytinburnu/İstanbul"
              allowFullScreen=""
            ></iframe>
          </Map>
        </MapContainer>
      </Body>
    </Container>
  );
}

export { ContactUs };
