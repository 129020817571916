import styled from "styled-components";
import { ReactComponent as Address } from "./address.svg";
import { ReactComponent as Phone } from "./phone.svg";
import { ReactComponent as Mail } from "./mail.svg";
import { ReactComponent as Website } from "./website.svg";
import { ReactComponent as Facebook } from "./facebook.svg";
import { ReactComponent as Instagram } from "./instagram.svg";
import { ReactComponent as Whatsapp } from "./logos-whatsapp.svg";
import { ReactComponent as BriefCase } from "./briefcase.svg";
import { ReactComponent as GoogleMaps } from "./googlemaps.svg";
import { Link } from "react-router-dom";

const Header = styled.div`
  margin-left: 10px;
  @media (max-width: 990px) {
    margin-left: 3px;
  }
`;
const Container = styled.div`
  margin: 31px 116px 112px 33px;

  @media (max-width: 1190px) {
    margin: 31px 33px 112px 33px;
  }

  @media (max-width: 990px) {
    margin: 50px 21px 121px 20px;
  }
`;
const Title = styled.div`
  font-size: 36px;
  font-family: Sansation-Bold;
  margin-bottom: 16px;

  @media (max-width: 990px) {
    font-size: 24px;
    margin-bottom: 7px;
  }
`;
const Body = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 57px;

  @media (max-width: 990px) {
    margin-top: 13px;
  }
`;
const Map = styled.div`
  background: white;
  width: 612px;
  height: 612px;
  @media (max-width: 1190px) {
    width: 550px;
    height: 550px;
  }
`;

const AddressIcon = styled(Address)`
  margin-bottom: 27px;
  flex-shrink: 0;
`;
const InstagramIcon = styled(Instagram)`
  width: 61px;
  height: 61px;
  cursor: pointer;

  @media (max-width: 990px) {
    width: 56.1px;
    height: 56.1px;
  }
`;
const FacebookIcon = styled(Facebook)`
  width: 62px;
  height: 62px;
  margin-right: 20px;
  cursor: pointer;

  @media (max-width: 990px) {
    width: 57px;
    height: 57px;
    margin-right: 18px;
  }
`;

const InfoTitle = styled.div`
  font-family: Sansation-Bold;
  margin-bottom: 14px;
`;
const SmallAddressIcon = styled(Address)`
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  margin-right: 21px;
`;
const PhoneIcon = styled(Phone)`
  margin-right: 21px;
`;
const BriefCaseIcon = styled(BriefCase)`
  margin-right: 59px;
  @media (max-width: 990px) {
    margin-right: 39px;
  }
`;
const WhatsappIcon = styled(Whatsapp)`
  flex-shrink: 0;
  margin-right: 14px;

  @media (max-width: 990px) {
    margin-right: 13px;
  }
`;
const WebsiteIcon = styled(Website)`
  margin-right: 21px;
`;
const MailIcon = styled(Mail)`
  margin-right: 21px;
`;
const ListItem = styled.li`
  display: flex;
  margin-bottom: 16px;
`;

const Information = styled.div`
  margin-top: 4px;
  margin-right: 15px;
  font-size: 18px;

  @media (max-width: 990px) {
    margin-right: 0;
  }
`;
const Underline = styled.div`
  background-color: black;
  max-width: 361px;
  height: 5px;
  margin-bottom: 58px;

  @media (max-width: 990px) {
    width: 162px;
    height: 2px;
    margin-bottom: 0;
  }
`;
const AddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 423px;
  margin-bottom: 4px;
`;
const PhoneContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const MailContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const WebsiteContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const SocialMedia = styled.div`
  margin-bottom: 27px;

  @media (max-width: 990px) {
    margin-top: 23px;
    order: 1;
    padding: 0 12px;
  }
`;
const List = styled.div`
  margin-bottom: 41px;

  @media (max-width: 990px) {
    margin: 0px 3px 18px 3px;
  }
`;

const WhatsappContainer = styled.div`
  display: flex;
  margin-bottom: 28px;
  padding: 12px 4px 16px 16px;
  border-radius: 21px;
  align-items: center;
  max-width: 359px;
  cursor: pointer;

  background-image: linear-gradient(
    to top,
    rgba(59, 232, 44, 0.45),
    rgba(44, 177, 33, 0.79)
  );

  @media (max-width: 990px) {
    max-width: 351px;
    padding: 13px 17px 15px 12px;
    margin-bottom: 18px;
  }
`;
const OrderForm = styled(Link)`
  display: flex;
  align-items: center;
  max-width: 361px;
  padding: 17.4px 0px 16.6px 18px;
  border-radius: 21px;
  background-image: linear-gradient(to top, #ffa767, #ff6e30);
  margin-bottom: 10px;

  @media (max-width: 990px) {
    margin-bottom: 18px;
  }
`;
const InfoText = styled.div`
  font-size: 14px;

  @media (max-width: 990px) {
    padding: 0 18px;
  }
`;
const OrderFormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const ContactNumbers = styled.ul`
  list-style-type: disc;
`;
const Contact = styled.li`
  &:first-child {
    margin-bottom: 20px;
  }
`;
const MapContainer = styled.div`
  margin-top: 7px;

  @media (max-width: 990px) {
    display: none;
  }
`;
const Bottom = styled.div`
  display: flex;
  flex-direction: column;
`;
const MapButtonContainer = styled.div`
  display: none;
  @media (max-width: 990px) {
    display: flex;
    background: linear-gradient(271.78deg, #ececec 1.54%, #ffffff 99.73%);
    border-radius: 21px;
    padding: 7px 28px 6px 18px;
    align-items: center;
    margin-bottom: 18px;
    max-width: 334px;
  }
`;
const GoogleMapsIcon = styled(GoogleMaps)`
  display: flex;
  flex-direction: column;
  margin-right: 44px;
`;
const WhatsappText = styled.div`
  margin-top: 4px;
  @media (max-width: 990px) {
    margin-top: 0;
  }
`;

export {
  Header,
  Container,
  Title,
  Underline,
  Body,
  Map,
  InstagramIcon,
  FacebookIcon,
  AddressIcon,
  SmallAddressIcon,
  Information,
  PhoneIcon,
  WebsiteIcon,
  MailIcon,
  BriefCaseIcon,
  ListItem,
  AddressContainer,
  PhoneContainer,
  MailContainer,
  WebsiteContainer,
  InfoTitle,
  SocialMedia,
  List,
  WhatsappContainer,
  OrderForm,
  InfoText,
  WhatsappIcon,
  OrderFormContainer,
  ContactNumbers,
  Contact,
  MapContainer,
  Bottom,
  MapButtonContainer,
  GoogleMapsIcon,
  WhatsappText,
};
