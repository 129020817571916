import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 27px;
  margin-left: auto;
  margin-right: auto;
  width: 700px;
  height: 1100px;

  @media (max-width: 730px) {
    width: 550px;
    height: 1100px;
  }

  @media (max-width: 570px) {
    width: 400px;
    height: 1100px;
  }

  @media (max-width: 390px) {
    width: 350px;
    height: 1150px;
  }

  @media (max-width: 350px) {
    width: 300px;
    height: 1200px;
  }

  @media (max-width: 300px) {
    width: 250px;
    height: 1300px;
  }
`;

export { Container };
