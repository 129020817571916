import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { HomePage } from "./HomePage";
import { AboutUs } from "./AboutUs";
import { Products } from "./Products";
import { ContactUs } from "./ContactUs";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { OrderForm } from "./OrderForm";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

function App() {
  return (
    <Router>
      <I18nextProvider i18n={i18n}>
        <div className="test">
          <Header />
          <main>
            <Switch>
              <Route exact path="/" component={HomePage} />
              <Route exact path="/hakkimizda" component={AboutUs} />
              <Route exact path="/urunlerimiz" component={Products} />
              <Route exact path="/iletisim" component={ContactUs} />
              <Route exact path="/siparis-kaydi" component={OrderForm} />
            </Switch>
          </main>
          <Footer />
        </div>
      </I18nextProvider>
    </Router>
  );
}

export default App;
