import { Link } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as Whatsapp } from "./whatsapp.svg";
import { ReactComponent as Facebook } from "./facebook.svg";
import { ReactComponent as Instagram } from "./instagram.svg";
import { ReactComponent as Logo } from "./logo.svg";
import { ReactComponent as PhoneIcon } from "./phone.svg";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 58px 0 19px;
  border-radius: 32px 32px 0 0;
  border: 1px solid black;
  background-color: #cbcbcb;
  align-items: center;
  margin: 0 102px;
  font-size: 12px;

  @media (max-width: 990px) {
    margin: 0;
    border-radius: 15px 15px 0 0;
    padding: 11px 9px 14px 9px;
  }

  @media (max-width: 360px) {
    margin: 0;
    border-radius: 15px 15px 0 0;
    padding: 11px 2px 14px 2px;
  }

  @media (max-width: 313px) {
    margin: 0;
    border-radius: 15px 15px 0 0;
    padding: 11px 2px 14px 2px;
    justify-content: center;
  }
`;

const Navigation = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1 1;
  margin-top: 1px;
  padding: 0 90px;
  @media (max-width: 1100px) {
    padding: 0 30px;
  }
  @media (max-width: 990px) {
    display: none;
  }
`;
const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 990px) {
    margin-bottom: 0;
    margin-right: 17px;
  }
  @media (max-width: 360px) {
    margin-right: 8px;
  }
`;
const Contact = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
  @media (max-width: 990px) {
    margin-bottom: 0;
  }
`;
const Information = styled.div`
  border-right: 3px solid black;
  padding: 8px 27px 3px 19px;
  @media (max-width: 990px) {
    display: flex;
    flex-direction: row-reverse;
    border: none;
    padding: 0;
  }
`;
const Copyright = styled.div`
  font-family: Sansation-Bold;
  font-size: 10px;
  margin-bottom: 4px;
  @media (max-width: 990px) {
    margin-bottom: 0px;
  }
`;
const PhoneNumber = styled.div`
  font-size: 14px;
  @media (max-width: 990px) {
    font-size: 13px;
  }
`;
const StyledLogo = styled(Logo)`
  width: 24px;
  height: 24px;
  margin-right: 4px;
`;

const StyledLink = styled(Link)``;

const SocialMedia = styled.div`
  display: flex;
  margin-left: 19px;
  margin-bottom: 2px;
  @media (max-width: 990px) {
   margin-bottom:0;
   margin-left:0;
  }
}
`;

const StyledFacebook = styled(Facebook)`
  margin-right: 10px;

  @media (max-width: 990px) {
    width: 22px;
    height: 22px;
    margin-right: 12px;
  }

  @media (max-width: 360px) {
    margin-right: 4px;
  }
`;

const StyledPhoneIcon = styled(PhoneIcon)`
  margin-left: 1px;
  margin-right: 6px;

  @media (max-width: 990px) {
    width: 13px;
    height: 13px;
    margin-right: 6px;
  }
  @media (max-width: 360px) {
    margin-right: 3px;
  }
`;

const StyledInstagram = styled(Instagram)`
  margin-right: 10px;
  width: 30px;
  height: 30px;
  @media (max-width: 990px) {
    width: 22px;
    height: 22px;
    margin-right: 11px;
  }

  @media (max-width: 360px) {
    margin-right: 4px;
  }
`;

const StyledWhatsapp = styled(Whatsapp)`
  margin-right: 10px;
  width: 29px;
  height: 29px;
  @media (max-width: 990px) {
    width: 23px;
    height: 22px;
    margin-right: 0;
  }
`;

export {
  Container,
  Navigation,
  StyledLink as Link,
  SocialMedia,
  StyledFacebook as Facebook,
  StyledInstagram as Instagram,
  StyledWhatsapp as Whatsapp,
  StyledPhoneIcon as PhoneIcon,
  Information,
  StyledLogo as Logo,
  LogoContainer,
  Contact,
  Copyright,
  PhoneNumber,
};
